import React from "react";
import RecipesList from "../Images/recipes-list.png";
import CalendarWithTodo from "../Images/calendar-with-todo.png";
import TodayWithTodo from "../Images/today-with-todo.png";
import Welcome from "./Welcome";
import {Helmet} from "react-helmet";

const Home = () => {
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Recipe Grocery</title>
            <meta name="description" content="Easy meal planning and grocery shopping list generation, custom recipe calendar, recipe preparation" />
            <link rel="canonical" href="http://recipegrocery.com" />
        </Helmet>
       <Welcome
        name="Easy meal planning and grocery shopping list generation"
        msg="Make meal planning effort less with a custom recipe calendar, recipe research and
                preparation less repetitive, and grocery shopping more efficient. Meal planning app that is focused on making meal planing, preparation, and research so easy
                which leads to efficient grocery shopping."
        imgsrc1={CalendarWithTodo}
        imgsrc2={TodayWithTodo}
        imgsrc3={RecipesList}
        visit="/features"
        btnname="Learn more"
      />
    </>
  );
};

export default Home;

import React , {useState} from "react";
import {Helmet} from 'react-helmet';

const Contact = () => {

  const [data,SetData] = useState({
    fullname:'',
    phone:'',
    email:'',
    msg:'',
  });

  const InputEvent = (event) =>{
    const {name,value}=event.target;
    SetData((preVal) =>{
      return {
        ...preVal,
        [name]:value,
      };
    });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    alert(`My name is ${data.fullname}. My Mobile number is ${data.phone} `);
  };

  return (
    <div className="foo">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recipe Grocery</title>
        <meta name="description" content="Contact Us" />
        <link rel="canonical" href="http://recipegrocery.com" />
      </Helmet>
      <div className="my-5">
        <h1 className="text-center"> Contact Us </h1>
      </div>
      <div className="container contact_div">
        <div className="row">
          <div className="col-md-6 col-10 mx-auot">
            <form onSubmit={formSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                   Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="fullname"
                  value={data.fullname}
                  onChange={InputEvent}
                  placeholder="Enter Your Name..."
                />
                 <label htmlFor="exampleFormControlInput1" className="form-label">
                   Phone
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="phone"
                  value={data.phone}
                  onChange={InputEvent}
                  placeholder="Enter Your Phone Number..."
                />
                 <label htmlFor="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="email"
                  value={data.email}
                  onChange={InputEvent}
                  placeholder="name@example.com"
                />
                </div>
                <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">
                   Message
                </label>
                <textarea
                className="form-control"
                 id="exampleFormControlInput1"
                 rows="3"
                 name="msg"
                 value={data.msg}
                 onChange={InputEvent}
                 ></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn-outline-primary" type="submit">
                     submit form
                  </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

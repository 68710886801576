import Web from '../Images/s1.jpg';
import app from '../Images/app.jpg';
import ss1 from '../Images/ss/ss1.png';
import ss2 from '../Images/ss/ss2.png';
// import ss3 from '../Images/ss/ss3.png';
// import ss4 from '../Images/ss/ss4.png';
import ss5 from '../Images/ss/ss5.png';
import ss6 from '../Images/ss/ss6.png';
import ss7 from '../Images/ss/ss7.png';
import ss8 from '../Images/ss/ss8.png';
import ss9 from '../Images/ss/ss9.png';
import ss10 from '../Images/ss/ss10.png';
import ss11 from '../Images/ss/ss11.png';
import ss12 from '../Images/ss/ss12.png';
import ss13 from '../Images/ss/ss13.png';

const Sdata = [
    {
        imgsrc:ss7,
        title:"Download content using recipe URL.",
        subheader: 'Build you recipe library in no time!',
        msgs: 'With millions of recipes online, it is so easy to get content from websites ' +
            'that support Schema.org standards. This make the process to create recipes so ' +
            'easy and quick and helps take useful notes about each recipe based on each ' +
            'invidual experience while make each recipe. For example someone that makes pizza ' +
            'for the first time would be a lot different from someone experienced that is at the level of writing ' +
            'a recipe online.',
    },
    {
        imgsrc:ss2,
        title:"Generate grocery lists.",
        subheader: 'Instantly sort, measure ingredients!',
        msgs: 'Creating complex recipes often times require a lot of ingredients with different measures, ' +
            'and when one plans a lot of those meals it could be considered almost a side project. With RecipeGrocery' +
            ' generating list of ingredients becomes a piece of cake with exact measurements.',
    },
    {
        imgsrc:ss5,
        title:"Access on any modern browser",
        subheader: 'Cross platform support!',
        msgs: 'There will be times when you may find it easier to browse recipes on your labtop ' +
            'or you desktop, on any modern browser access your recipe collection and grow it.',
    },
    {
        imgsrc:ss8,
        title:"Unlimited recipe creation",
        subheader: 'Discover food you probably haven\'t tried before!',
        msgs: 'Create recipes like a pro chef, download from your favorite websites or ' +
            'create creative ones and put your name on it.',
    },
    {
        imgsrc:ss9,
        title:"Unlimited ingredients creation",
        subheader: 'Did you know your kitchen has hundreds of ingredients!',
        msgs: 'Everytime you import a recipe, we ll import its ingredients but in case ' +
            'you want to add special ones, you can add unlimited ingredients.',
    },
    {
        imgsrc:ss10,
        title:"Unlimited menus creation",
        subheader: 'Every season deserves a menu, and every day of the week, and every holiday! Yeah why not!',
        msgs: 'Imagine you suddenly have so many menus, maybe 52 menus one for each week! ' +
            'How about an Easter menu? or a halloween menu? The possibilities are endless. ',
    },
    {
        imgsrc:ss11,
        title:"Cooking 2 or more recipes at the same time",
        subheader: 'Work on two recipes at the same time and switch between ingredients and preparation steps seamlessly!',
        msgs: '',
    },
    {
        imgsrc:ss1,
        title:"Calendar planning for up to 12 months",
        subheader: '',
        msgs: 'Drag and drop recipes, menus, or the history of previously setup meals. View calendar by day, ' +
            'week, month and start the week on monday, saturday or sunday.',
    },
    {
        imgsrc:ss6,
        title:"Work offline",
        subheader: 'No matter where you are!',
        msgs: 'Use the app offline like in the subways or in airplanes where no wifi or data access is available',
    },
    {
        imgsrc:ss12,
        title:"Synchronize data with other devices",
        subheader: '',
        msgs: 'It is easier to search for recipes on Ipad or desktop computer, your devices will synchronize automatically soon after a change happen,',
    },
    {
        imgsrc:ss13,
        title:"Available in 13 languages",
        subheader: 'choose your prefered language while you get organizer!',
        msgs: ' Albanian, Arabic, Chinese, Czech, English, French, German, Hindi, Italian, Portuguese, Russian, Spanish, Turkish',
    },
];

export default Sdata;

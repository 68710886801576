import React from "react";
import {NavLink} from "react-router-dom";
import GooglePlayBadge from "../Images/appss/google-play-badge.png";
import AppStoreBadgeWht from "../Images/appss/app_store_badge_wht.svg";
import Grocery from "../Images/grocery.svg";

const Common = (props) => {
  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-12 col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    {props.name}
                     {/*<strong className="brand-name"> Guptaji </strong>*/}
                  </h1>
                  <p className="my-3">
                    {props.msg}
                  </p>
                  <div className="mt-3">
                    <NavLink to={props.visit} className="btn-get-started">
                      {props.btnname}
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 header-img">
                  <img src={props.imgsrc1} className="img-fluid img-slider animated" alt="home" />
                  {props.imgsrc2 ? <img src={props.imgsrc2}
                                        className="img-fluid img-slider img-slider2 animated"
                                        alt="home" /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Common;

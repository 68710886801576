import React from "react";
import Sdata from './Sdata';
import AppStoreBadgeWht from '../Images/appss/app_store_badge_wht.svg';
import GooglePlayBadge from '../Images/appss/google-play-badge.png';
import Grocery from '../Images/grocery.svg';
import {Helmet} from 'react-helmet';

const Services = () => {
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Recipe Grocery</title>
            <meta name="description" content="20+ Application features" />
            <link rel="canonical" href="http://recipegrocery.com" />
        </Helmet>
      <div className="my-5">
        <h1 className="text-center"> App features </h1>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
              <div className="row gy-6">
                  {
                      Sdata.map((val,idx) =>{
                          return <div className="row feature-card">
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                  <div className="col-10 mx-auto">
                                      <div className="card border-0">
                                          <div className="card-body">
                                              <h5 className="card-title">{val.title}</h5>
                                              <p className="card-text">
                                                  {val.subheader}
                                              </p>
                                          </div>
                                          <img src={val.imgsrc} className="card-img-top" alt="new_course" className="set"/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                  <div className="card feature-txt border-0">
                                      <div className="card-body">
                                          <p>{val.msgs}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      })
                  }
              </div>
          </div>
        </div>
      </div>
        <section className='bottom-links'>
            <h3>Download in the app store</h3>
            <div className="mt-3 apps-links">
                <a target="_blank" href="https://apps.apple.com/us/app/id1546421705">
                    <button className="btn">
                        <img height="42px" alt="Apple app store"
                             src={AppStoreBadgeWht}/>
                    </button>
                </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.recipegrocery.app">
                    <button className="btn gp-btn">
                        <img height="58px" alt="Google play store"
                             src={GooglePlayBadge}/>
                    </button>
                </a>
            </div>
        </section>
    </>
  );
};

export default Services

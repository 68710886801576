import React from "react";
import Web from "../Images/web.jpg";
import Common from './Common';
import CalendarWithTodo from '../Images/calendar-with-todo.png';
import {Helmet} from 'react-helmet';

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recipe Grocery</title>
        <meta name="description" content="shop ingredients for recipes you enjoy" />
        <link rel="canonical" href="http://recipegrocery.com" />
      </Helmet>
    <Common
        name="Welcome to Recipe Grocery"
        imgsrc1={CalendarWithTodo}
        msg="The goal of this app is to shop ingredients for recipes you enjoy, and avoid the repetitive tasks such as planning future meals. The idea was to simply generate a list of shopping ingrediets then maybe share it with others. "
        visit="/contact"
        btnname="Contact Now"
      />

    </>
  );
};

export default About;

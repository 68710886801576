import React from "react";
import { NavLink } from "react-router-dom";
// import Grocery from '../Images/grocery.svg';
import menuGrid from '../Images/menugrid.svg';

const Navbar = () => {
  return (
    <>
      <div className="container-fluid nav_bg top-banner">
        <div className="row">
          <div className="col-10 mx-auto">
            <p className="text-danger">Download in the App Store for <b>FREE</b></p>
          </div>
        </div>
      </div>
      <nav className="navbar top-navbar navbar-expand-lg">
        <div className="container">
          {/*<NavLink className="navbar-brand" to="/">*/}
          {/*  Aman Gupta*/}
          {/*</NavLink>*/}
          <NavLink exact className="nav-link" to="/">
            <h1 className="logo">
              <div className="row">
                {/*<div className="col-3">*/}
                {/*  <img height="42px" alt="Desktop App"*/}
                {/*       src={Grocery}/>*/}
                {/*</div>*/}
                <div className="col-12">
                  <span>Recipe</span>
                  <span>Grocery</span>
                </div>
              </div>
            </h1>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <img src={menuGrid} />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink activeClassName="menu_active" exact className="nav-link text-black" to="/pricing">
                  Pricing
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="menu_active" exact className="nav-link text-black" to="/features">
                  App Features
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="menu_active" exact className="nav-link text-black" to="/contact">
                  <b>FOR BUSINESS</b>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React from "react";
import xhrRequest from '../services/xhr';
import environment from '../config';
const TermsOfUse = () => {
  let termsOfUse = '';
  xhrRequest({
    url: `${environment.backendUrl}/v1/data/terms-of-use`,
    method: 'GET'
  }).then(data => {
    termsOfUse = JSON.parse(data).content;
    document.getElementById('terms').innerHTML = termsOfUse;
  })
  return (
    <div id="terms"></div>
  );
};

export default TermsOfUse;

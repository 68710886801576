import React from "react";
// import Web from "../Images/web.jpg";
// import Common from './Common';
// import CalendarWithTodo from '../Images/calendar-with-todo.png';
import xhrRequest from '../services/xhr';
import environment from '../config';

const PrivacyPolicy = () => {
  let privacyPolicy = '';
  xhrRequest({
    url: `${environment.backendUrl}/v1/data/privacy-policy`,
    method: 'GET'
  }).then(data => {
    privacyPolicy = JSON.parse(data).content;
    document.getElementById('privacypolicy').innerHTML = privacyPolicy;
  })
  return (
      <div id="privacypolicy"></div>
  );
};

export default PrivacyPolicy;

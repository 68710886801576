const xhrRequest = (opts) => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = opts.url;
        let params = opts.query_params;
        if (params && typeof params === 'object') {
            params = Object.keys(params).map(function(key) {
                return encodeURIComponent(key).replace(/%20/g, '+') +
                    '=' + encodeURIComponent(params[key]).replace(/%20/g, '+');
            }).join('&');
            url = url + '?' + params;
        }
        xhr.open(opts.method, url);
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function() {
            reject({
                status: xhr.status,
                statusText: xhr.statusText
            });
        };
        if (opts.headers) {
            Object.keys(opts.headers).forEach(function(key) {
                xhr.setRequestHeader(key, opts.headers[key]);
            });
        }
        if (opts.method.toUpperCase() == 'POST'){
            xhr.send(opts.body);
        } else {
            xhr.send()
        }
    })
}

export default xhrRequest;

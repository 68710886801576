import Web from '../Images/s1.jpg';
import Splash from '../Images/splash.png';
// import app from '../Images/app.jpg';
// import IOT from '../Images/Iot.jpg';
// import digital from '../Images/Digital.jpg';
// import marketing from '../Images/marketing.jpg';
// import software from '../Images/software.jpg';

const Subscriptiondata = [
    {
        imgsrc:Splash,
        title:"$19.99/Year",
        msgs: "Subscribe in the App store and unlock all application features"
    }
];

export default Subscriptiondata;

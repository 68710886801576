import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact'
import Pricing from './Components/Pricing';
import Features from './Components/Features';
import HowItWorks from './Components/HowItWorks';
import TermsOfUse from './Components/TermsOfUse';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import {Switch , Route , Redirect} from "react-router-dom";
// import {browserHistory} from "react-router";
class App extends React.Component {

    componentDidMount() {


    }
    // componentDidUpdate(prevProps) {
    //     console.log('componentDidUpdate', prevProps);
    // }
    componentWillUnmount() {
        console.log('componentWillUnmount');
        // this.unlisten();
        //
    }
    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', prevProps, this.props.location, prevProps.location);
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        console.log("ROUTE CHANGED");
    }
    render() {
        return (
            <>
              <Navbar/>
              <Switch>
               <Route exact path='/' component={Home}/>
               <Route exact path='/about' component={About}/>
               <Route exact path='/contact' component={Contact}/>
               <Route exact path='/pricing' component={Pricing}/>
               <Route exact path='/features' component={Features}/>
               <Route exact path='/privacy' component={PrivacyPolicy}/>
               <Route exact path='/terms-of-use' component={TermsOfUse}/>
               <Route exact path='/how-it-works' component={HowItWorks}/>
               <Route exact path='/advertise' component={Contact}/>
               <Redirect to="/" />
              </Switch>
              <Footer/>
            </>
        );
    }
};

export default App

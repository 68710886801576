import React, {useEffect, useLayoutEffect} from "react";
import GooglePlayBadge from "../Images/appss/google-play-badge.png";
import AppStoreBadgeWht from "../Images/appss/app_store_badge_wht.svg";
import Grocery from "../Images/grocery.svg";
import BgVideo from "../Images/bg-video.mp4";
import BgOven from "../Images/bg-oven.mp4";
import AddRecipe from "../Images/addrecipe.mp4";
import AddEvent from "../Images/addevent.mp4";
import ProCart from "../Images/pro-cart.mp4";
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router-dom';
import ss2 from '../Images/ss/ss2.png';
function tick(imagesArr) {
  const element = (
      imagesArr
          .map((imgsrc, idx) =>
              <img src={imgsrc} key={idx}
                   className={'img-slider' + (idx + 1) +' img-fluid img-slider animated'}
                   alt="home" />)

  );
  ReactDOM.render(
      element,
      document.getElementById('promo')
  );
}

function tickVideo(videoArr) {
  const element = (
      videoArr
          .map((vdosrc, idx) =>
              <div><h4>{idx !== 3 ? (idx + 1 + ' - ') : ''}{idx === 0 ? 'Create recipes' :
                  (idx === 1? 'Add recipes to calendar': (idx === 2 ? 'Shop ingredients' : ''))}</h4>
                {idx === 2 ? <img className='mw300' src={vdosrc} /> : <video src={vdosrc} autoPlay loop muted></video>}</div>)

  );
  ReactDOM.render(
      element,
      document.getElementById('promovideo')
  );
}

const Welcome = (props, images, videoArr) => {
  images = [];
  videoArr = [AddRecipe, AddEvent, ss2, ProCart];
  if (props.imgsrc1) images.push(props.imgsrc1);
  if (props.imgsrc2) images.push(props.imgsrc2);
  if (props.imgsrc3) images.push(props.imgsrc3);
  document.body.classList.add('welcome-page');
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      // This will be evaluated on every route change. So, if the flash alert
      // exists already and the route has changed, we want to clear it by calling
      // our state update function (setFlashAlert) with null.
      console.log('route change')
      // if (flashAlert) {
      //     setFlashAlert(null);
      // }
    });
    // This function will be invoked on component unmount and will clean up
    // the event listener.
    return () => {
      unlisten();
    };
  }, [history, () => {
    console.log('route change callback i guess!!')
  }]);
  setTimeout(() => {
    tick(images)
    tickVideo(videoArr);
  }, 400)
  const intvr = setInterval(() => {
    images.sort(() => Math.random() - 0.5);
    tick(images)
  }, 3000);
  // const intvr2 = setInterval(() => {
  //   videoArr.sort(() => Math.random() - 0.5);
  //   tickVideo(videoArr)
  // }, 20000);
  // setTimeout(() => {
  //   clearInterval(intvr);
  //   clearInterval(intvr2);
  // }, 30000)
  useLayoutEffect(() => {
    return () => {
      console.log('use layout effect')
      clearInterval(intvr);
      // clearInterval(intvr2);
      document.body.classList.remove('welcome-page');
      // Your code here.
    }
  }, [])

  return (
    <>
      <section id="header" className="d-flex align-items-center intro">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-12 col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column">
                  <h1 className="text-white">
                    {props.name}
                     {/*<strong className="brand-name"> Guptaji </strong>*/}
                  </h1>
                  <p className="my-3 text-white">
                    {props.msg}
                  </p>
                  <div className="mt-3 apps-links">
                    <a target="_blank" href="https://apps.apple.com/us/app/id1546421705">
                      <button className="btn">
                        <img height="42px" alt="Apple app store"
                             src={AppStoreBadgeWht}/>
                      </button>
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.recipegrocery.app">
                      <button className="btn gp-btn">
                        <img height="58px" alt="Google play store"
                             src={GooglePlayBadge}/>
                      </button>
                    </a>
                    <button className="btn border-dark bg-warning wa-cta">
                      <a href="https://app.recipegrocery.com">
                        <img height="24px" alt="Desktop App"
                             src={Grocery}/>
                        <b className="text-black">Web App</b>
                      </a>
                    </button>
                  </div>
                </div>
                <div id="promo" className="col-lg-6 order-1 order-lg-2 header-img"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="promovideo">
        <video src={ProCart} autoPlay loop muted></video>
      </section>
      <section className='bottom-links'>
        <h3>Download in the app store</h3>
        <div className="mt-3 apps-links">
          <a target="_blank" href="https://apps.apple.com/us/app/id1546421705">
            <button className="btn">
              <img height="42px" alt="Apple app store"
                   src={AppStoreBadgeWht}/>
            </button>
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.recipegrocery.app">
            <button className="btn gp-btn">
              <img height="58px" alt="Google play store"
                   src={GooglePlayBadge}/>
            </button>
          </a>
        </div>
      </section>
    </>
  );
};

export default Welcome;

import React from "react";
import Subscriptiondata from './Subscriptiondata';
import Card from './Card';
import AppStoreBadgeWht from '../Images/appss/app_store_badge_wht.svg';
import GooglePlayBadge from '../Images/appss/google-play-badge.png';
import { Helmet } from "react-helmet";

const Pricing = () => {
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Recipe Grocery</title>
            <meta name="description" content="Recipe Grocery Pricing & Subscriptions" />
            <link rel="canonical" href="http://recipegrocery.com" />
        </Helmet>
      <div className="my-5">
        <h1 className="text-center"> Pricing & Subscriptions </h1>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row gy-4">
               {
                   Subscriptiondata.map((val,idx) =>{
                    return <Card key={idx}
                      imgsrc={val.imgsrc}
                      title={val.title}
                      msgs={val.msgs}
                    />
                 })
               }
                <div className="mt-3 apps-links" style={{textAlign: 'center'}}>
                    <a target="_blank" href="https://apps.apple.com/us/app/id1546421705">
                        <button className="btn">
                            <img height="42px" alt="Apple app store"
                                 src={AppStoreBadgeWht}/>
                        </button>
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.recipegrocery.app">
                        <button className="btn gp-btn">
                            <img height="58px" alt="Google play store"
                                 src={GooglePlayBadge}/>
                        </button>
                    </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing

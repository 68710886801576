import React from 'react';
import {NavLink} from 'react-router-dom';

const Footer = () =>{
    const year = new Date().getFullYear();
   return (
       <>
         <footer className='w-100 bg-light text-center'>
             <ul className="list-inline footer-links">
                 <li className="list-inline-item">
                     <NavLink  activeClassName="menu_active" exact
                               className="nav-link" to="/how-it-works">
                         How it works
                     </NavLink>
                 </li>
                 <li className="list-inline-item">
                     <NavLink  activeClassName="menu_active" exact
                               className="nav-link" to="/advertise">
                         Advertise
                     </NavLink>
                 </li>
                 <li className="list-inline-item">
                     <NavLink  activeClassName="menu_active" exact
                               className="nav-link" to="/privacy">
                         Privacy policy
                     </NavLink>
                 </li>
                 <li className="list-inline-item">
                     <NavLink  activeClassName="menu_active" exact
                               className="nav-link" to="/terms-of-use">
                         Terms of use
                     </NavLink>
                 </li>
                 <li className="list-inline-item">
                     <NavLink  activeClassName="menu_active" exact
                               className="nav-link" to="/contact">
                         Contact us
                     </NavLink>
                 </li>
             </ul>
             <p>© {year} Powered by Baskhub LLC </p>
         </footer>
       </>
   );
};

export default Footer;

import React from "react";
// import Web from "../Images/web.jpg";
import Common from './Common';
import CalendarWithTodo from '../Images/calendar-with-todo.png';

const HowItWorks = () => {
  return (
    <>
    <Common
        name="Welcome to Recipe Grocery"
        imgsrc1={CalendarWithTodo}
        msg="The development of this app started in 2020,
        the idea was to simply generate a list of shopping ingrediets then maybe share it with others. The goal of this app is to shop ingredients for recipes you love, and avoid the repitative tasks such as planning future meals"
        visit="/contact"
        btnname="Contact Now"
      />

    </>
  );
};

export default HowItWorks;
